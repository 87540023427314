<template>
  <v-card
    flat
    tile
    color="white"
    class="py-16 pl-128 pr-128"
  >
    <v-row
      justify="center"
      align="center"
      no-gutters
      class="mt-10"
    >
      <v-form 
        ref="forgotForm" 
        v-model="valid"
        lazy-validation
      >
        <v-flex
          xs6
          mt-16
          offset-3
          lg6
          md6
          class="lg-6 md-6"
        >
          <AlertBox
            v-if="error"
            :message="error"
          />
          <AlertBox
            v-if="success"
            :message="success"
            color="light-green lighten-3"
          />
          <v-card-text class="text-h3 text-left pl-0 pb-1">
            {{ $t('reset_pwd') }}
          </v-card-text>
          <v-card-text class="text-left pl-0 pt-1">
            <p class="light-text text-h6 text-warp">
              {{ $t('reset_text') }}
            </p>
          </v-card-text>
          <v-text-field
            v-model="password"
            data-cy="Password"
            hide-details="auto"
            :placeholder="$t('password')"
            outlined
            required
            type="password"
            :rules="rules.password"
            :hint="!valid ? $t('pwd_hint') : ''"
            @keydown.enter.prevent="resetPassword()"
          />
          <v-btn
            large
            block
            color="primary"
            dark
            class="white--text my-4"
            :loading="loading"
            @click="resetPassword()"
          >
            {{ $t('reset_pwd') }}
          </v-btn>
        </v-flex>
      </v-form>
    </v-row>
  </v-card>
</template>

<script>
// utils
import { rules } from '@/utils/validation.js'
import i18n  from '@/utils/i18n.js'
// graphql
import { NEW_PASSWORD } from "@/graphql/authentication.js";

export default {
  name: "ForgotPassword",
  data() {
    return {
      error: null,
      success: null,
      password: null,
      rules,
      valid: false,
      loading: false,
      code: null,
    }
  },
  mounted(){
    if(this.$route.query.token){
      this.code = this.$route.query.token;
    }
  },
  methods: {
    /**
     * Reset password by email
     */
    async resetPassword() {
      this.error = null
      if (this.$refs.forgotForm.validate()) {
        this.loading = true
        try {
          const { data: { resetPassword } } = await this.$apollo.mutate({
            mutation: NEW_PASSWORD,
            variables: {
              resetInput: { 
                code: this.code,
                password: this.password,
              },
            },
          });
          
          if (resetPassword.success) {
            this.loading = false
            this.success = i18n.t('success_reset_pwd')
            setTimeout(() => {
              this.$router.push({ name: 'Home' })
            }, 2000)
          } else {
            this.loading = false
            this.error = this.$t(resetPassword.error)
          }
                  
        } catch (e) {
          this.loading = false
          this.error = e
        }
      }
    },
  },
};
</script>
<style scoped>
form{
    width:100%;
}
</style>